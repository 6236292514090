<script setup>
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import ELZindexProvider from 'element-plus'
const WIDTH = 1400
const meta = ref(null)

const updateViewportMeta = () => {
  if (!meta.value) return

  let scale = screen.width / WIDTH
  console.log(screen.width, scale, 2)
  let content = `width=${WIDTH}, initial-scale=${scale > 1 ? 1 : scale}, maximum-scale=1, minimum-scale=${scale > 1 ? 1 : scale}`
  meta.value.setAttribute('content', content)
}

onMounted(() => {
  meta.value = document.querySelector('meta[name=viewport]')
  if (!meta.value) {
    meta.value = document.createElement('meta')
    meta.value.setAttribute('name', 'viewport')
    document.head.appendChild(meta.value)
  }

  window.addEventListener('resize', updateViewportMeta)
  updateViewportMeta()
})

onUnmounted(() => {
  window.removeEventListener('resize', updateViewportMeta)
})
</script>
<template>
  <ElConfigProvider :locale="zhCn">
    <ElConfigProvider>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </ElConfigProvider>
  </ElConfigProvider>
</template>
